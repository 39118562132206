export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: bigint;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: Date;
};

export type AdminFullUsersResponse = {
  __typename?: 'AdminFullUsersResponse';
  total: Scalars['Int'];
  users: Array<UserAccount>;
};

export type AdminUpdateAccountStatusInput = {
  accountStatus: UserAccountStatus;
  targetAddress: Scalars['String'];
};

export type AuthenticateInput = {
  message: Scalars['String'];
  signature: Scalars['String'];
  walletAddress: Scalars['String'];
};

export type AuthenticateResult = {
  __typename?: 'AuthenticateResult';
  authenticatedAccount: UserAccount;
  token: Scalars['String'];
};

export type CancelDelegationResponse = {
  __typename?: 'CancelDelegationResponse';
  success: Scalars['Boolean'];
};

export type CreateAccountInput = {
  message: Scalars['String'];
  signature: Scalars['String'];
  walletAddress: Scalars['String'];
};

export type CreateAccountResult = {
  __typename?: 'CreateAccountResult';
  createdAccount: UserAccount;
  isAdmin: Scalars['Boolean'];
  token: Scalars['String'];
};

export type CreateNftDelegationInput = {
  delegateeId: Scalars['String'];
  delegatorId: Scalars['String'];
  nftIds: Array<Scalars['Int']>;
  signature: Scalars['String'];
};

export type CreateProposalInput = {
  description: Scalars['String'];
  durationInDays: Scalars['Int'];
  minimumApprovalPercentage?: InputMaybe<Scalars['Float']>;
  quorumPercentage?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
  voteOptions: Array<CreateVoteOptionInput>;
};

export type CreateVoteInput = {
  proposalId: Scalars['ID'];
  voteOptionId: Scalars['ID'];
};

export type CreateVoteOptionInput = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DaoInfo = {
  __typename?: 'DAOInfo';
  description: Scalars['String'];
  discord?: Maybe<Scalars['String']>;
  extraLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logo: Scalars['String'];
  telegram?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  twitter?: Maybe<Scalars['String']>;
};

export type DeleteProposalInput = {
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminUpdateAccountStatus: UserAccount;
  authenticate: AuthenticateResult;
  cancelDelegation: CancelDelegationResponse;
  createAccount: CreateAccountResult;
  createProposal: Proposal;
  createVote: Vote;
  delegateNFTs: Array<NftDelegation>;
  deleteProposal: Scalars['Boolean'];
  updateProposal: Proposal;
  vote: VoteResponse;
};


export type MutationAdminUpdateAccountStatusArgs = {
  input: AdminUpdateAccountStatusInput;
};


export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};


export type MutationCancelDelegationArgs = {
  delegationId: Scalars['Int'];
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateProposalArgs = {
  input: CreateProposalInput;
};


export type MutationCreateVoteArgs = {
  input: CreateVoteInput;
};


export type MutationDelegateNfTsArgs = {
  input: CreateNftDelegationInput;
};


export type MutationDeleteProposalArgs = {
  input: DeleteProposalInput;
};


export type MutationUpdateProposalArgs = {
  input: UpdateProposalInput;
};


export type MutationVoteArgs = {
  input: VoteInput;
};

export type Nft = {
  __typename?: 'NFT';
  aquiredAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  delegations: Array<NftDelegation>;
  id: Scalars['ID'];
  tokenID: Scalars['String'];
  user?: Maybe<UserAccount>;
  votes: Array<Vote>;
  walletAddress: Scalars['String'];
};

export type NftDelegation = {
  __typename?: 'NFTDelegation';
  delegatedAt: Scalars['DateTime'];
  delegatee: UserAccount;
  delegateeId: Scalars['String'];
  delegator: UserAccount;
  delegatorId: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  nft: Nft;
  nftId: Scalars['Int'];
};

export type NftsDelegationQueryInput = {
  activeDelegation: Scalars['Boolean'];
};

export type Proposal = {
  __typename?: 'Proposal';
  author: UserAccount;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  durationInDays: Scalars['Int'];
  id: Scalars['Int'];
  media: Array<ProposalMedia>;
  minimumApprovalPercentage?: Maybe<Scalars['Float']>;
  quorumPercentage?: Maybe<Scalars['Float']>;
  status: ProposalStatus;
  title: Scalars['String'];
  voteOptions: Array<VoteOption>;
  votes: Array<Vote>;
  walletAddress: Scalars['String'];
  winningVoteOptionId?: Maybe<Scalars['Int']>;
};

export type ProposalMedia = {
  __typename?: 'ProposalMedia';
  filename: Scalars['String'];
  id: Scalars['Int'];
  mimeType: Scalars['String'];
  originalName: Scalars['String'];
  path: Scalars['String'];
  proposalId: Scalars['Int'];
};

export enum ProposalStatus {
  Accepted = 'ACCEPTED',
  Closed = 'CLOSED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED'
}

export type Query = {
  __typename?: 'Query';
  daoInfo?: Maybe<DaoInfo>;
  fullUsers: AdminFullUsersResponse;
  nfts: Array<Nft>;
  nftsDelegation: Array<Nft>;
  nftsOwnedAndDelegated: Array<Nft>;
  proposal?: Maybe<Proposal>;
  proposals: Array<Proposal>;
  testField?: Maybe<Scalars['Int']>;
  user: UserAccountResponse;
  userAccount?: Maybe<UserAccount>;
  userPenalties: Array<UserPenalty>;
  userVotes: Array<Vote>;
  users: Array<UserAccountResponse>;
  viewer?: Maybe<Viewer>;
  votes: Array<Vote>;
};


export type QueryFullUsersArgs = {
  accountStatus?: InputMaybe<UserAccountStatus>;
  address?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryNftsArgs = {
  walletAddress: Scalars['String'];
};


export type QueryNftsDelegationArgs = {
  input: NftsDelegationQueryInput;
  walletAddress: Scalars['String'];
};


export type QueryNftsOwnedAndDelegatedArgs = {
  walletAddress: Scalars['String'];
};


export type QueryProposalArgs = {
  id: Scalars['ID'];
};


export type QueryProposalsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ProposalStatus>;
};


export type QueryUserArgs = {
  walletAddress?: InputMaybe<Scalars['String']>;
};


export type QueryUserAccountArgs = {
  walletAddress: Scalars['String'];
};


export type QueryUserPenaltiesArgs = {
  walletAddress: Scalars['String'];
};


export type QueryUserVotesArgs = {
  walletAddress: Scalars['String'];
};


export type QueryUsersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryVotesArgs = {
  proposalId: Scalars['ID'];
};

export type UpdateProposalInput = {
  deadline?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  status?: InputMaybe<ProposalStatus>;
  title?: InputMaybe<Scalars['String']>;
  voteOptions?: InputMaybe<Array<UpdateVoteOptionInput>>;
};

export type UpdateVoteOptionInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type UserAccount = {
  __typename?: 'UserAccount';
  accountStatus: UserAccountStatus;
  isAdmin: Scalars['Boolean'];
  nonce?: Maybe<Scalars['String']>;
  walletAddress: Scalars['String'];
};

export type UserAccountResponse = {
  __typename?: 'UserAccountResponse';
  walletAddress?: Maybe<Scalars['String']>;
};

export enum UserAccountStatus {
  Active = 'ACTIVE',
  Banned = 'BANNED',
  Inactive = 'INACTIVE'
}

export type UserPenalty = {
  __typename?: 'UserPenalty';
  createdAt?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<UserPenaltyStatus>;
  vote?: Maybe<Vote>;
  walletAddress?: Maybe<Scalars['String']>;
};

export enum UserPenaltyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Viewer = {
  __typename?: 'Viewer';
  walletAddress: Scalars['String'];
};

export type Vote = {
  __typename?: 'Vote';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  member: UserAccount;
  nfts: Array<Nft>;
  proposal: Proposal;
  voteOption: VoteOption;
};

export type VoteInput = {
  proposalId: Scalars['ID'];
  tokenIds: Array<Scalars['String']>;
  voteOptionId: Scalars['ID'];
  walletAddress: Scalars['String'];
};

export type VoteOption = {
  __typename?: 'VoteOption';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  proposal: Proposal;
  title: Scalars['String'];
};

export type VoteResponse = {
  __typename?: 'VoteResponse';
  id: Scalars['ID'];
  invalidTokenIds: Array<Scalars['String']>;
};
