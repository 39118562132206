export const abbreviateAddress = (address: string) => {
  return `${address.slice(0, 4)}...${address.slice(-4)}`;
};

export function getColorForStatus(status) {
  switch (status) {
    case 'IN_PROGRESS':
      return 'text-yellow-400';
    case 'CLOSED':
      return 'text-green-500';
    case 'ACCEPTED':
      return 'text-green-500';
    case 'REJECTED':
      return 'text-red-500';
    default:
      return 'text-gray-500';
  }
}

export function formatWalletAddress(address) {
  if (!address || address.length < 11) {
    return address;
  }
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}
